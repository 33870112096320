import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

interface Props {
  title: string
  children: React.ReactNode
}

const Page: FC<Props> = ({ title, children }) => (
  <Container>
    <Helmet>
      <title>{title} | tagline Admin Panel</title>
    </Helmet>
    {children}
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 200px);
  padding: 20px;
  background-color: #f5f5f5;

  @media screen and (max-width: 856px) {
    min-width: calc(100% - 56px);
  }
`

export { Page }
