import React, { FC, ReactNode } from 'react'
import Icon from 'components/atoms/Icon'
import Button from 'components/molecules/Button'
import { SearchInputController } from 'components/molecules/SearchInput'
import styled from 'styled-components'
import Paragraph from 'components/atoms/Paragraph'
import Headline from 'components/atoms/Headline'
import { useTranslation } from 'react-i18next'
import Filter from 'components/molecules/Filter'

interface Props {
  title: string
  subtitle?: string
  search?: string
  filter?: React.ReactNode
  isFilterActive?: boolean
  onChangeSearch?: (query: string) => void
  onClearFilters?: () => void
  onExportCsv?: () => void
  actions?: ReactNode
}

const PageHeader: FC<Props> = ({
  title,
  subtitle,
  search,
  filter,
  isFilterActive,
  onChangeSearch,
  onClearFilters,
  onExportCsv,
  actions,
}) => {
  const [t] = useTranslation()
  return (
    <>
      <Header>
        <Title>
          <Headline size="H3" text={title} />
          <Gap />
          {subtitle && <Subtitle size="12" text={subtitle} />}
        </Title>

        {onChangeSearch && (
          <SearchBar>
            {filter && <Filter filter={filter} isFilterActive={isFilterActive} onClearFilters={onClearFilters} />}
            {onExportCsv && (
              <Button icon={<Icon icon="uil:import" />} appearance="flat" onClick={() => onExportCsv()}>
                {t('common.exportCsv')}
              </Button>
            )}
            <SearchInputController onChange={onChangeSearch} value={search!} />
          </SearchBar>
        )}
      </Header>
      <ActionBar>{actions}</ActionBar>
    </>
  )
}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  min-height: 40px;
`
const Title = styled.div`
  display: flex;
  align-items: center;
`

const Gap = styled.div`
  width: 10px;
`

const Subtitle = styled(Paragraph)`
  margin-top: 2px;
  color: ${(p) => p.theme.colors.coolGrey2};
`

const SearchBar = styled.div`
  display: grid;
  grid: auto / auto auto auto;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
`
const ActionBar = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;
  justify-content: end;
`

export { PageHeader }
