import React, { FC } from 'react'
import styled from 'styled-components'
import { useMutation, useQueryClient } from 'react-query'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { createBroadcastsChannelFn } from 'api'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { Realm } from '@types'

const AddChannel: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const realms = queryClient.getQueryData('realms') as Realm[]

  const {
    control,
    formState: { isValid, isSubmitted },
    handleSubmit,
  } = useForm<{ name: string }>()

  const { mutate } = useMutation(createBroadcastsChannelFn, {
    onError: () => {
      toast.error(t('modal.addBroadcastChannel.createError'))
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['broadcast-channels'])
      toast.success(t('modal.addBroadcastChannel.createSuccess'))
      onClose()
    },
  })

  const onSubmit = ({ name }: { name: string }) => {
    mutate({
      name,
      i_realm: realms[0].i_realm,
    })
  }

  return (
    <ModalView title={t('modal.addBroadcastChannel.title')} style={{ maxWidth: '400px' }} onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field>
          <Controller
            name="name"
            defaultValue=""
            rules={{ required: true }}
            control={control}
            render={({ field: { ref, ...props } }) => (
              <InputWithCaptions type="text" caption={t('common.name')} {...props} />
            )}
          />
        </Field>

        <Button appearance="blue" type="submit" disabled={!isValid || isSubmitted} style={{ placeSelf: 'flex-end' }}>
          {t('common.create')}
        </Button>
      </Form>
    </ModalView>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const Field = styled.div`
  margin-bottom: 16px;
`

export default AddChannel
