import React from 'react'
import { ReactComponent as LogoSvg } from 'components/assets/logo.svg'
import { Center } from 'components/atoms/Center'
import styled from 'styled-components'

export default function NavHeader() {
  return (
    <Container>
      <Center>
        <Logo />
      </Center>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr auto;
  margin-bottom: 32px;
`

const Logo = styled(LogoSvg)`
  width: 104px;
  fill: #1c658e;
  @media screen and (max-width: 856px) {
    width: 52px;
  }
`
