import React, { FC } from 'react'
import styled from 'styled-components'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { useMutation, useQueryClient } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { editFeatureGroupsFn } from 'api'
import { FeatureGroup, Realm } from '@types'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import Loader from 'components/molecules/Loader'
import { useTranslation } from 'react-i18next'
import { SwitchField } from '../components/SwitchField'
import { useUserContext } from 'contexts/UserContext'
import { Roles } from 'utils/consts'

// const registrationModeOptions: { label: string; value: number }[] = [
//   { label: 'phone', value: 0 },
//   { label: 'username', value: 1 },
//   { label: 'phone / username', value: 2 },
//   { label: 'phone + precheck', value: 3 },
//   { label: 'username + precheck', value: 4 },
//   { label: 'phone+username+precheck', value: 5 },
// ]

const EditFeatureGroup: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const params = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const realms = queryClient.getQueryData('realms') as Realm[]

  const { state } = useUserContext()
  const isSuperAdmin = state.admin?.type === Roles.SuperAdmin

  const featureGroups: FeatureGroup[] | undefined = queryClient.getQueryData('feature-groups')
  const featureGroup = featureGroups?.find(({ i_featuregroup }) => +params.id! === i_featuregroup)

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm<FeatureGroup>()

  const { mutate: editFeatureGroup } = useMutation(
    (data: Partial<FeatureGroup>) => editFeatureGroupsFn({ ...data, i_realm: realms[0].i_realm }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('feature-groups')
        onClose()
      },
    }
  )

  const onSubmit = (values: FeatureGroup) => {
    editFeatureGroup(values)
  }

  useEffect(() => {
    if (featureGroup) {
      reset(featureGroup)
    }
  }, [featureGroup, reset])

  if (!featureGroup) {
    return <Loader height="30vh" />
  }

  return (
    <ModalView title={t('modal.editFeatureGroup.title')} style={{ maxWidth: '750px' }} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Controller
              name="name"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, name, ...props } }) => (
                <InputWithCaptions type="text" caption="Name" {...props} />
              )}
            />
          </Field>

          <Grid>
            <SwitchField name="external_contact_triggers" title="External contact triggers" control={control} />
            <SwitchField name="local_contacts_editing" title="Contacts editing" control={control} />
            <SwitchField name="local_contacts_only" title="Allow local contacts" control={control} />
            <SwitchField name="roster_contacts" title="Load contacts form server" control={control} />
            <SwitchField name="show_channels" title="Show channels" control={control} />
            <SwitchField name="multiple_devices" title="Multiple devices" control={control} />

            {isSuperAdmin && (
              <>
                <SwitchField name="external_auth" title="External Auth" control={control} />
                <SwitchField name="auto_message_disappear" title="Auto message dissapear" control={control} />
                <SwitchField name="call_recording" title="Call recording" control={control} />

                <SwitchField name="disable_p2p" title="Disable p2p" control={control} />
                <SwitchField name="hide_app" title="Hide app" control={control} />
                <SwitchField name="message_security_options" title="Message security options" control={control} />
                <SwitchField name="multiple_identities" title="Multiple identities" control={control} />
                <SwitchField name="notarized_receipts" title="Notarized receipts" control={control} />
                <SwitchField name="privacy" title="Privacy" control={control} />
                <SwitchField name="promo_credits" title="Promo credits" control={control} />
                <SwitchField name="ptt" title="Allow push-to-talk" control={control} />
                <SwitchField name="server_contacts" title="Allow server contacts" control={control} />
                <SwitchField name="show_location_during_call" title="Show location during call" control={control} />
                <SwitchField name="unrestricted_voip" title="Unrestricted VoIP" control={control} />
                <SwitchField name="video_conf" title="Video conference" control={control} />

                <SwitchField name="cr_auto_activate" title="CR auto activate" control={control} />
                <SwitchField name="cr_save_mode" title="CR save mode" control={control} />
                <SwitchField name="cr_show_controls" title="CR show controls" control={control} />
                <SwitchField name="ptt_default_speaker" title="PTT default speaker" control={control} />
                <SwitchField
                  name="ptt_force_enable_from_contacts"
                  title="PTT force enable from contacts"
                  control={control}
                />
                <div />

                <Controller
                  name="max_daily_transfer"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, name, ...props } }) => (
                    <InputWithCaptions type="number" caption="Max daily transfer" {...props} />
                  )}
                />
                <Controller
                  name="max_transfer_amount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, name, ...props } }) => (
                    <InputWithCaptions type="number" caption="Max transfer amount" {...props} />
                  )}
                />
                <Controller
                  name="min_leftover_balance"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, name, ...props } }) => (
                    <InputWithCaptions type="number" caption="Min leftover balance" {...props} />
                  )}
                />
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <GreyParagraph2
                    style={{ marginLeft: '15px', marginBottom: '5px' }}
                    size="11"
                    text="Registration mode"
                  />
                  <Controller
                    name="registration_mode"
                    rules={{ required: true }}
                    defaultValue={registrationModeOptions[0].value}
                    control={control}
                    render={({ field: { ref, onChange, value, ...props } }) => {
                      const val = registrationModeOptions.find(({ value: v }) => v === value)
                      return (
                        <ReactSelect
                          value={val}
                          onChange={({ value }) => {
                            onChange(value)
                          }}
                          options={registrationModeOptions}
                          placeholder="Choose registration mode"
                          {...props}
                        />
                      )
                    }}
                  />
                </div> */}
              </>
            )}
          </Grid>

          <SubmitButton appearance="blue" type="submit" disabled={!isDirty || !isValid}>
            {t('modal.editFeatureGroup.edit')}
          </SubmitButton>
        </Form>
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

const Grid = styled.div`
  display: grid;
  max-height: calc(100vh - 280px);
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: 10px;
  width: 100%;
  overflow: auto;
`

const Field = styled.div`
  display: flex;
  margin-bottom: 24px;
  gap: 12px;
  & > * {
    width: 230px;
  }
`

const SubmitButton = styled(Button)`
  margin-top: 32px;
  align-self: flex-end;
`

export default EditFeatureGroup
