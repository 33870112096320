import { AdminUser } from '@types'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { createContext, useReducer } from 'react'

type State = {
  admin: AdminUser | null
}

type Dispatch = (action: Action) => void

type Action = {
  type: string
  payload: AdminUser | null
}
interface UserContextValue {
  token?: string
  refreshToken?: string
  setToken: (token: string) => void
  setRefreshToken: (token: string) => void
  state: State
  dispatch: Dispatch
}
type UserContextProviderProps = { children: React.ReactNode }

const initialContextValue = {
  setToken() {},
  setRefreshToken() {},
  dispatch() {},
  state: {
    admin: null,
  },
}

export const UserContext = createContext<UserContextValue>(initialContextValue)

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [token, setToken] = useLocalStorage('token')
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token')
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const values = { state, dispatch, token, setToken, refreshToken, setRefreshToken }
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>
}

const initialState: State = {
  admin: null,
}
const stateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_ADMIN': {
      return {
        ...state,
        admin: action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

export const useUserContext = () => {
  const context = React.useContext(UserContext)

  if (context) {
    return context
  }

  throw new Error(`useUserContext must be used within a UserContextProvider`)
}
