import React, { useRef, FC, HTMLAttributes, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { ModalLayout } from './ModalLayout'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { CloseButton } from 'components/molecules/Button'

interface Props extends HTMLAttributes<HTMLDivElement> {
  open: boolean
  onClose: () => void
}

const modalRoot = document.getElementById('modal-root')

const Modal: FC<Props> = ({ open, onClose, children }: Props) => {
  const modalCardRef = useRef(null)
  useOnClickOutside(modalCardRef, onClose)

  return createPortal(open && <ModalLayout modalCardRef={modalCardRef}>{children}</ModalLayout>, modalRoot!)
}

interface ModalViewProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  onClose: () => void
}

const ModalView: FC<ModalViewProps> = ({ children, title, onClose, ...rest }) => (
  <ModalContainer {...rest}>
    <ModalHeader actions={<CloseButton size="large" onClick={onClose} />}>{title}</ModalHeader>
    <ModalContent>{children}</ModalContent>
  </ModalContainer>
)

const StyledModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-header__title {
    font-size: 18px;
    font-weight: 600;
    color: #2e2e2e;
  }

  .modal-header__actions {
    display: flex;
    > * {
      margin-left: 8px;
    }
  }
`

const ModalHeader: FC<{
  children: ReactNode
  actions: ReactNode | ReactNode[]
}> = ({ children, actions }) => (
  <StyledModalHeader>
    <div className="modal-header__title">{children}</div>
    <div className="modal-header__actions">{actions}</div>
  </StyledModalHeader>
)

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 32px;
  padding: 16px 32px;
`

const ModalContainer = styled.div`
  width: 80vw;
  max-width: 650px;
`

export default Modal
export { ModalView }
